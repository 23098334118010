export const experienceData = [
    {
        id: 1,
        company: ' Alcohol Abuse , Drug addiction , Food addiction , Gambling addiction , Quit smoking.',
        jobtitle: 'Addiction and Substance Abuse',
        startYear: '2018',
        endYear: '2019'
    },
    {
        id: 2,
      
        company: ' Anger management , Anxiety , Depression , Exam stress , Fear of flying , Low self-confidence , Low self-esteem , Obsessions and compulsions , Panic attacks , Phobias , PTSD , Public speaking , Relationship issues , Stress .',
        jobtitle: 'Mental Health and Emotional Wellbeing',
        startYear: '2019',
        endYear: '2020'
    },
    {
        id: 3,
        company: 'RTA Accidents , Bruxism , Chronic Fatigue Syndrome, Eating Problems , Gastric band hypnosis, Insomnia , Irritable bowel syndrome , Pain management ,Relaxation , Sleep Problems , Weight Loss .',
        jobtitle: 'Physical Health and Lifestyle',
        startYear: '2020',
        endYear: 'Present'
    },
]