import React, { useContext, useState } from 'react';
import './About.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { aboutData } from '../../data/aboutData';

function About() {
    const { theme } = useContext(ThemeContext);
    const [showMore, setShowMore] = useState(false);

    const handleReadMore = () => {
        setShowMore(!showMore);
    };

    return (
        <div className="about" id="about" style={{ backgroundColor: theme.secondary, textAlign: 'justify' }}>
            <div className="line-styling">
                <div className="style-circle" style={{ backgroundColor: theme.primary }}></div>
                <div className="style-circle" style={{ backgroundColor: theme.primary }}></div>
                <div className="style-line" style={{ backgroundColor: theme.primary }}></div>
            </div>
            <div className="about-body">
                <div className="about-description">
                    <h2 style={{ color: theme.primary }}>{aboutData.title}</h2>
                    <p style={{ color: theme.tertiary80 }}>
                        {/* {aboutData.description1}<br />
                        {aboutData.description4}<br /><br/> */}
                        {aboutData.description2}
                        {showMore && <><br /><br />{aboutData.description3}</>}
                    </p>
                    <button 
                        onClick={handleReadMore} 
                        style={{
                            marginTop:'10px',
                            padding: '10px 20px', 
                            fontSize: '16px', 
                            border: 'none', 
                            borderRadius: '5px', 
                            cursor: 'pointer', 
                            backgroundColor: theme.primary, 
                            color: 'black', 
                            transition: 'all 0.3s ease', 
                            boxShadow: showMore ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none',
                        }}
                        onMouseOver={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.5)'}
                        onMouseOut={(e) => e.currentTarget.style.boxShadow = 'none'}
                    >
                        {showMore ? 'Show Less' : 'Read More'}
                    </button>
                </div>
                <div className="about-img">
                    <img 
                        src={aboutData.image === 1 ? theme.aboutimg1 : theme.aboutimg2}  
                        alt="" 
                    />
                </div>
            </div>
        </div>
    );
}

export default About;
