/* eslint-disable */
import girl1 from '../assets/svg/testimonials/girl1.svg'
import girl2 from '../assets/svg/testimonials/girl2.svg'
import girl3 from '../assets/svg/testimonials/girl3.svg'
import boy1 from '../assets/svg/testimonials/boy1.svg'
import boy2 from '../assets/svg/testimonials/boy2.svg'
import boy3 from '../assets/svg/testimonials/boy3.svg'



export const testimonialsData = [
    {
        id: 1,
        name: 'Chandler Bing',
        title: 'Solicitor',
        text: "She provides detailed and precise medico-legal reports that are invaluable for our cases. Her clear communication and professionalism make her a trusted expert in her field.",
        image: boy2
    },
    {
        id: 2,
        name: 'Monica Geller',
        title: 'Client',
        text: "Dr. Adriana has been transformative in my life. Her compassionate approach and practical advice have helped me manage my anxiety and depression. I feel genuinely heard and supported. Highly recommended!",
        image: girl2
    },
    {
        id: 3,
        name: 'Phoebe Buffay',
        title: 'CBT Client',
        text: "I've made significant progress with Dr.Adriana's CBT sessions. Her knowledge and supportive demeanor have helped me manage my thoughts and behaviors effectively. I highly recommend her",
        image: girl1
    },
    {
        id: 4,
        name: 'John Alice',
        title: 'Agency',
        text: "Dr. Adriana expertise in RTA and medico-legal cases has greatly benefited our clients. She delivers thorough reports and is genuinely committed to their well-being. A valuable asset to our team.",
        image: boy1
    },
    // {
    //     id: 5,
    //     name: 'Rachel Green',
    //     title: 'Sales Executive',
    //     text: 'Ralph Lauren was one of the best things that ever happened to me. My portfolio made that dream happen. I owe you guys.',
    //     image: girl3
    // },
    // {
    //     id: 6,
    //     name: 'Ross Geller',
    //     title: 'Paleontologist',
    //     text: "Be it 'Rocks' or 'Bones', you need a website to display it. This is it. Great work guys !",
    //     image: boy3
    // }
]

