export const educationData = [
    {
        id: 1,
        institution: 'UK Academy of Therapeutic Arts And Science ',
        course: 'Diploma in Clinical and Advanced Hypnosis- DipCAH HPD ',
        startYear: '2020',
        endYear: '2023'
    },
    {
        id: 2,
        institution: 'Degree in Clinical Psychology ',
        course: 'Master`s Degree ',
        startYear: 'October 2015',
        endYear: 'July 2017'
    },
    {
        id: 3,
        institution: 'Degree in Educational Psychology ',
        course: 'Master`s Degree ',
        startYear: 'October 2015',
        endYear: 'July 2017'
    },
    {
        id: 4,
        institution: 'Degree in Psychology',
        course: 'Bachelor Degree ',
        startYear: 'October 2010',
        endYear: 'July 2013'
    },
]