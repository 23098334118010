
import Individual from '../assets/png/individual.png'
import Couple from '../assets/png/couple.png'
import group from '../assets/png/group.png'
import crisis from '../assets/png/crisis.png'
import evidence from '../assets/png/evidence.png'
import immegration from '../assets/png/immegration.png'
import trauma from '../assets/png/trauma.png'
import medico from '../assets/png/medico.png'
import family from '../assets/png/family.png'




export const blogData = [
    {
        id: 1,
        projectName: 'Individual Therapy',
        projectDesc:'•	Anxiety and Panic Disorders',
       projectDesc1:'•	Depression and Mood Disorders',
       projectDesc2:'•	Trauma-related Disorders (including PTSD)',
       projectDesc3:'•	Personality Disorders',
       projectDesc4:'•	Autism Spectrum Disorders (ASD)', 
       tags: [ 'Registration Number', 'PYL039655'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
         image: Individual
    },
    {
        id: 2,
        projectName: 'Couple Therapy',
        projectDesc: '•	Relationship Counseling',
        projectDesc1:'•	Intimacy-related Issues',
        projectDesc2:'•	Communication Skills',
        projectDesc3:'•	Conflict Resolution',
        projectDesc4:'•	Promoting Personal Growth',
        tags: ['Registered with', 'BPS'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: Couple
    },
    {
        id: 3,
        projectName: ' Group Therapy',
        projectDesc: '•	Support Groups for Mental Health Conditions',
        projectDesc1:'•	Psychoeducational Groups',
        projectDesc2:'•	Support Network',
        projectDesc3:'•	Diverse Perspectives',
        projectDesc4:'•	Shared Experiences', 
        tags: ['Registered with', 'NCH'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: group
    },
    {
        id: 4,
        projectName: 'Family Therapy',
        projectDesc: '•Enhanced Communication Skills',
        projectDesc1:'• Improved Family Relationships',
        projectDesc2:'• Effective Conflict Resolution',
        projectDesc3:'•	Strengthened Family Bonds',
        projectDesc4:'•	Support for Mental Health Issues',
        tags: ['React Native', 'Firebase'],
        // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: family
    },
     {
         id: 4,
        projectName: 'Trauma and Abuse Therapy',
        projectDesc: '•	Survivors of Physical',
        projectDesc1:'•	Coping Strategies',
        projectDesc2:'•	Trauma-informed Care',
        projectDesc3:'•	Emotional, and Sexual Abuse',
        projectDesc4:'•	Processing Trauma',
        tags: ['Flutter', 'Firebase'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: trauma
    },
    {
        id: 5,
        projectName: 'Evidence-Based Therapeutic Approaches',
        projectDesc: '•	Cognitive Behavioral Therapy (CBT)',
        projectDesc1:'•	Dialectical Behavior Therapy (DBT)',
        projectDesc2:'•	Acceptance and Commitment Therapy (ACT)',
        projectDesc3:'•	Mindfulness-Based Therapies',
        projectDesc4:'•	Solution-Focused Brief Therapy (SFBT)',
        
        tags: ['Flutter'],
        // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: evidence
    },
    {
        id: 6,
        projectName: 'Medico Legal  Support',
        projectDesc: '•Expert Testimony',
        projectDesc1:'•	Legal Consultation',
        projectDesc2:'• Injury Assessment',
        projectDesc3:'•	Professional Witness Services',
        projectDesc4:'•	Medical Report Writing',
        tags: ['React Native', 'Firebase'],
        // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: medico
    },

   

    {
        id: 7,
        projectName: 'Crisis Intervention',
        projectDesc: '•	Immediate Support for Acute Mental Health Crises',
        projectDesc1:'•	Suicide Risk Assessment and Management',
        projectDesc2:'•	Safety Planning',
        projectDesc3:'•	Personality Disorders',
        projectDesc4:'•	Short-Term Approach',
        tags: ['React', 'Redux', 'Bootstrap'],
        // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: crisis
    },
    {
        id: 8,
        projectName: 'Immigration Report Services',
        projectDesc: '•	Psychological Evaluations for Immigration Cases',
        projectDesc1:'•	Reports for Asylum Seekers and Refugees',
        projectDesc2:'•	Assessments for Visa and Residency Applications',
        projectDesc3:'•	Guidance and Support',
        projectDesc4:'•	Representation', 
        tags: ['Flutter', 'React'],
        // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: immegration
    },



]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, */
