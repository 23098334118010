export const achievementData = {
    bio: "Over the past decade, I have managed a private clinical psychology practice, focusing on assessing, planning, and evaluating treatment plans for diverse clients with various disabilities. I support clients in achieving daily independence and maintain detailed records of their progress. Previously, as a Managing Partner at Geometria Consulting NGO from 2009 to 2014, I assessed client needs, provided counseling, and developed treatment programs for individuals facing anxiety, depression, and addictions, always striving to maintain the highest standards of care.",
    achievements: [
        {
            id: 1,
            title:'Private Clinical Psychology Practice',
            details:'To assess, plan, implement, and evaluate treatment plans.',
            details1:'Assisting individuals of all ages with disabilities to perform daily tasks more confidently and independently.',
            details2:'Creating treatment programs and suggesting environmental changes.',
            details3:'Reviewing treatments periodically, evaluating progress, and making changes as needed.',
            details4:'Working with a diverse range of people who all had different requirements.',
            date:'August 2010-Present',
            field:'Clinical Psychologist',
            image:'https://tse1.mm.bing.net/th?id=OIP.rXZx7rBJK2ebyLYJSme4kAAAAA&pid=Api&P=0&h=220'
        },
        {
            id: 2,
            title: 'Geometria Consulting NGO',
            details: 'Evaluating clients\' needs and recommending suitable and effective treatment methods.',
            details1: 'Handling a diverse workload of clients dealing with anxiety, depression, emotional problems, and addictions.',
            details2: 'Assessing clients\' needs, abilities, or behavior using a variety of methods.',
            details3: 'Devising and monitoring appropriate programs of treatment for clients.',
            details4: 'Evaluating and making decisions about clients\' treatment options.',
            date: 'Sep 2009-2014',
            field: 'Managing Partner',
            image: 'https://tse1.mm.bing.net/th?id=OIP.JQqPcnXiDRuc8ohjKIZZDQHaHa&pid=Api&P=0&h=220'
        }
    ]
}
