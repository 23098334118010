import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { makeStyles } from '@material-ui/core/styles';
import { TypeAnimation } from 'react-type-animation';
import './Landing.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { headerData } from '../../data/headerData';
import { socialsData } from '../../data/socialsData';


import {
    FaFacebook,
    FaInstagram,
    FaLinkedin,
    FaWhatsapp,
} from 'react-icons/fa';


function Landing() {
    const { theme, drawerOpen } = useContext(ThemeContext);

    const useStyles = makeStyles((t) => ({
        resumeBtn: {
            color: theme.primary,
            borderRadius: '30px',
            textTransform: 'inherit',
            textDecoration: 'none',
            width: 'auto',
            fontSize: '1rem',
            fontWeight: '500',
            height: 'auto',
            fontFamily: 'var(--primaryFont)',
            border: `3px solid ${theme.primary}`,
            transition: '100ms ease-out',
            '&:hover': {
                backgroundColor: theme.tertiary,
                color: theme.secondary,
                border: `3px solid ${theme.tertiary}`,
            },
            [t.breakpoints.down('sm')]: {
                width: 'auto',
            },
        },
        contactBtn: {
            backgroundColor: theme.primary,
            color: theme.secondary,
            borderRadius: '30px',
            textTransform: 'inherit',
            textDecoration: 'none',
            width: '150px',
            height: 'auto',
            fontSize: '1rem',
            fontWeight: '500',
            fontFamily: 'var(--primaryFont)',
            border: `3px solid ${theme.primary}`,
            transition: '100ms ease-out',
            '&:hover': {
                backgroundColor: theme.secondary,
                color: theme.tertiary,
                border: `3px solid ${theme.tertiary}`,
            },
            [t.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    }));

    const classes = useStyles();

    return (
        <div className='landing'>
            <div className='landing--container'>
                <div
                    className='landing--container-left'
                    style={{ backgroundColor: theme.primary }}
                >
                    <div className='lcl--content'>
                        {socialsData.facebook && (
                            <i
                                href={socialsData.facebook}
                                target='_blank'
                                rel='noreferrer'
                            >
                               <FaFacebook
                                    className='landing--social'
                                    style={{ color: theme.secondary }}
                                    aria-label='Twitter'
                                />
                            </i>
                        )}
                        {socialsData.linkedIn && (
                            <a
                                href={socialsData.linkedIn}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FaLinkedin
                                    className='landing--social'
                                    style={{ color: theme.secondary }}
                                    aria-label='YouTube'
                                />
                            </a>
                        )}
                        {socialsData.instagram && (
                            <i
                                href={socialsData.instagram}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FaInstagram
                                    className='landing--social'
                                    style={{ color: theme.secondary }}
                                    aria-label='Blogger'
                                />
                            </i>
                        )}
                        {socialsData.whatsapp && (
                            <a
                                href={socialsData.whatsapp}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FaWhatsapp
                                    className='landing--social'
                                    style={{ color: theme.secondary }}
                                    aria-label='Instagram'
                                />
                            </a>
                        )}
                       
                    </div>
                </div>
                <img
                    src={headerData.image}
                    alt=''
                    className='landing--img'
                    style={{
                        opacity: `${drawerOpen ? '0' : '1'}`,
                        borderColor: theme.secondary,
                    }}
                />
                <div
                    className='landing--container-right'
                    style={{ backgroundColor: theme.secondary,textAlign:'justify' }}
                >
                    <div
                        className='lcr--content'
                        style={{ color: theme.tertiary,paddingTop:'25px' }}
                    >
                        <h6>{headerData.title}</h6>
                        <h1>{headerData.name}</h1>
                       <TypeAnimation
      sequence={[
        'Individual Therapy',
        2500,
        'Couple Therapy',
        2500,
        'Trauma and Abuse Therapy',
        2500,
        'Consultation and Supervision',
        2500,
        'Crisis Intervention Therapy',
        2500,
        'Evidence-Based Approaches',
         2500,
        'Specialized Care for Older Adults',
        2500,
        'Immigration Report Services',
        2500,
      ]}
      wrapper="span"
      speed={25}
      style={{ color:'rgb(190, 80, 46)',fontSize: '1.1em', display: 'inline-block' }}
      repeat={Infinity}
    />

                        <p>{headerData.desciption}</p>

                        <div className='lcr-buttonContainer'>
                            {/* {headerData.resumePdf && (
                                <a
                                    href={headerData.resumePdf}
                                    download='resume'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <Button className={classes.resumeBtn}>
                                        Download CV
                                    </Button>
                                </a>
                            )} */}
                            <NavLink
                                to='/#contacts'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <Button className={classes.contactBtn}>
                                    Contact
                                </Button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;
