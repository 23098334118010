import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Fade from 'react-reveal/Fade';

import placeholder from '../../../assets/png/placeholder.png';
import './SingleBlog.css';

function SingleBlog({ id, name, desc,desc1,desc2,desc3,desc4, tags, code, demo, image, theme  }) {
    const useStyles = makeStyles((t) => ({
        iconBtn: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: 50,
            border: `2px solid ${theme.tertiary}`,
            color: theme.tertiary,
            transition: 'all 0.2s',
            '&:hover': {
                backgroundColor: theme.secondary,
                color: theme.primary,
                transform: 'scale(1.1)',
                border: `2px solid ${theme.secondary}`,
            },
        },
        icon: {
            fontSize: '1.1rem',
            transition: 'all 0.2s',
            '&:hover': {},
        },
    }));

    const classes = useStyles();
    return (
        <Fade bottom>
        <div
            key={id}
            className='singleProject'
            style={{ backgroundColor: theme.primary400 }}
        >
            <div className='projectContent'>
                <h2
                    id={name.replace(' ', '-').toLowerCase()}
                    style={{ color: theme.tertiary }}
                >
                    {name}
                </h2>
                <img src={image ? image : placeholder} alt={name} />
               
            </div>
            <p
                className='project--desc'
                style={{
                    background: theme.secondary,
                    color: theme.tertiary,
                    height:250,
                    fontSize:16
                }}
            >
                {desc}<br/> {desc1}<br/> {desc2}<br/> {desc3}<br/> {desc4}
            </p>
            {/* <div
                className='project--lang'
                style={{
                    background: theme.secondary,
                    color: theme.tertiary80,
                }}
            >
                {tags.map((tag, id) => (
                    <span key={id}>{tag}</span>
                ))}
            </div> */}
        </div>
    </Fade>
    )
}

export default SingleBlog
