import one from '../assets/png/hcpc1.avif'
import bps from '../assets/png/bps.png'
import NCH from '../assets/png/NCH.jpeg'



export const projectsData = [
    {
        id: 1,
        projectName: 'HCPC Certificate',
        projectDesc: 'The Health and Care Professions Council (HCPC) is a UK regulatory body ensuring health and care professionals meet high standards of competence and ethics. Registration with the HCPC signifies trustworthiness, ongoing professional development, and adherence to strict codes of conduct.',
        tags: [ 'Registration Number', 'PYL039655'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
         image: one
    },
    {
        id: 2,
        projectName: ' BPS Certificate',
        projectDesc: 'Being registered with the British Psychological Society (BPS) signifies adherence to high standards of practice and ethics in psychology. It provides access to valuable resources and a professional network for continuous development, fostering excellence in the field.',

        tags: ['Registered with', 'BPS'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: bps
    },
    {
        id: 3,
        projectName: ' NCH Certificate',
        projectDesc: 'Being registered with the National Council of Hypnotherapy (NCH) demonstrates a commitment to professional standards and ethical practice in hypnotherapy. It provides access to ongoing training,resources, and a supportive community of practitioners.',
        tags: ['Registered with', 'NCH'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: NCH
    },
    //  {
    //      id: 4,
    //     projectName: 'Android Patient Tracker',
    //     projectDesc: 'This project involves the development of an Android application for viewing and managing patient data.',
    //     tags: ['Flutter', 'Firebase'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: one
    // },
    // {
    //     id: 5,
    //     projectName: 'E-Commerce App',
    //     projectDesc: 'A Simple E-commerce application',
    //     tags: ['React Native', 'Firebase'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: bps
    // },
    // {
    //     id: 6,
    //     projectName: 'Uber Lite',
    //     projectDesc: 'Uber clone',
    //     tags: ['Flutter'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: NCH
    // },
    // {
    //     id: 7,
    //     projectName: 'Stock Market App',
    //     projectDesc: 'A simple stock market API app',
    //     tags: ['React', 'Redux', 'Bootstrap'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     // image: seven
    // },
    // {
    //     id: 8,
    //     projectName: 'Car Pooling System',
    //     projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
    //     tags: ['Flutter', 'React'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: eight
    // },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, */
