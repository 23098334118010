

export const socialsData = {
    github: 'https://github.com/',
    facebook: 'https://www.facebook.com',
    linkedIn: 'https://www.linkedin.com/in/dr-adriana-pavelescu-b2991743?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bh9ara4pfSQaQX6RMDRZcWA%3D%3D',
    instagram: 'https://www.instagram.com/',
    codepen: 'https://codepen.io/',
    twitter: 'https://twitter.com/',
    reddit: 'https://www.reddit.com/user/',
    blogger: 'https://www.blogger.com/',
    medium: 'https://medium.com/@',
    stackOverflow: 'https://stackoverflow.com/users/',
    gitlab: 'https://gitlab.com/',
    youtube: 'https://youtube.com/',
    whatsapp:'https://wa.me/+447903670958?text=Welcome! How can i help You?',
    tiktok:'https://tiktok.com/'
}